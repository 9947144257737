export const config = {
    RPC_URL: 'https://joe-rpc.polkachu.com:443',
    REST_URL: 'https://joe-api.polkachu.com:443',
    // EXPLORER_URL: 'https://explorer.cheqd.io',
    STAKING_URL: 'https://joe.omniflix.co/stake',
    NETWORK_NAME: 'Joe',
    NETWORK_TYPE: 'mainnet',
    CHAIN_ID: 'joe-1',
    CHAIN_NAME: 'Just Joe',
    COIN_DENOM: 'JOE',
    COIN_MINIMAL_DENOM: 'ujoe',
    COIN_DECIMALS: 6,
    PREFIX: 'joe',
    COIN_TYPE: 118,
    COSMOSTAION: null,
    COINGECKO_ID: 'terra-luna-2',
    GAS_PRICE_STEP_LOW: 0.01,
    GAS_PRICE_STEP_AVERAGE: 0.025,
    GAS_PRICE_STEP_HIGH: 0.03,
    FEATURES: ['ibc-transfer', 'ibc-go'],
};
